import React from 'react';
import { shape, object, string } from 'prop-types';
import Helmet from 'react-helmet';
import { Link, graphql } from 'gatsby';

class TagRoute extends React.Component {
  static propTypes = {
    data: shape({
      allMarkdownRemark: object.isRequired,
      site: object.isRequired,
    }).isRequired,
    pageContext: shape({
      tag: string.isRequired,
    }).isRequired,
  };

  renderPostLinks() {
    const posts = this.props.data.allMarkdownRemark.edges;
    return posts.map((post) => (
      <li key={post.node.fields.slug}>
        <Link to={post.node.fields.slug}>
          <h2>{post.node.frontmatter.title}</h2>
        </Link>
      </li>
    ));
  }

  render() {
    const { tag } = this.props.pageContext;
    const { title } = this.props.data.site.siteMetadata;
    const { totalCount } = this.props.data.allMarkdownRemark;
    const tagHeader = `${totalCount} post${
      totalCount === 1 ? '' : 's'
    } tagged with “${tag}”`;

    return (
      <section>
        <Helmet title={`${tag} | ${title}`} />
        <div>
          <div>
            <div style={{ marginBottom: '6rem' }}>
              <h3>{tagHeader}</h3>
              <ul>{this.renderPostLinks()}</ul>
              <p>
                <Link to="/tags/">Browse all tags</Link>
              </p>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default TagRoute;

export const tagPageQuery = graphql`
  query TagPage($tag: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`;
